import React, { useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { fab } from '@fortawesome/free-brands-svg-icons'
// Layout
import MainLayout from './components/layout/MainLayout';
import Booking from './components/booking/booking';
import Confirmation from './components/confirmation/confirmation-page';
import { ReservationContext } from './context/reservation.context';
import { reservationData } from './interfaces/reservation.interface';

import {
  faPlaneArrival,
  faPlaneDeparture,
  faCalendarAlt,
  faUsers,
  faUser,
  faSuitcase,
  faRoute,
  faExclamationTriangle,
  faShuttleVan,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faPenAlt,
  faLock,
  faUserFriends,
  faFileInvoiceDollar,
  faSignOutAlt,
  faDownload,
  faEdit,
  faCreditCard,
  faMoneyBillWave,
  faCalendarCheck,
  faCheck

} from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
import CheckoutPage from './components/checkout-page/checkout-page';

library.add(faCheck, faCalendarCheck, faMoneyBillWave,faCreditCard, fab, faSignOutAlt, faDownload, faFileInvoiceDollar, faPlaneArrival, faPlaneDeparture, faCalendarAlt, faUsers, faSuitcase, faRoute, faExclamationTriangle, faShuttleVan, faMapMarkedAlt, faMapMarkerAlt, faUser, faPhone, faEnvelope, faPenAlt, faLock, faUserFriends, faEdit);

const App = () => {
  const [languageController, setLanguageController] = useState('eng');
  const [reservation, setReservation] = useState(reservationData)
  return (
    <ReservationContext.Provider value={{
        reservation,
        setReservation,
        lang:languageController
    }} >
      <BrowserRouter>
          <MainLayout langController={setLanguageController} lang={languageController} >
              <Route path='/' exact component={() => <Booking lang={languageController}/>} />
              <Route path='/confirmation/:id' exact component={Confirmation} />
              <Route path='/checkout/:id' exact component={CheckoutPage} />
          </MainLayout>
      </BrowserRouter>
    </ReservationContext.Provider>
  );
}

export default App;
