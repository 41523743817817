import React, { useState, useEffect, Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
    Alert,
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    InputGroup,
    Label,
    Input,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalBody,
    FormFeedback,
    TabContent,
    TabPane,
    FormText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUnits } from "../../api_controllers/units_controller";
import { getLocations } from "../../api_controllers/locations_controller";
import { getAirlines } from "../../api_controllers/airlines_controller";
import { getRateByPlaces } from "../../api_controllers/rates_controller";
import { postReservation } from "../../api_controllers/reservations_controller";
// import { sendMail, parserEmailData } from "../../api_controllers/email_controller";

import "moment/locale/cs.js";
import "moment/locale/es.js";
import "moment/locale/fr.js";
import "moment/locale/nl.js";
import moment from "moment";

import LoadingOverlay from "react-loading-overlay";

import Select from "react-select";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";

import Swal from "sweetalert2";
import Summary from "./summary";
import Sidebar from "./sidebar";
import { DateTimePickerForm } from "./dateTime-picker-form";
import { ReservationContext } from "../../context/reservation.context";

registerLocale("es", es);
registerLocale("en", en);

export default function Booking({ lang, params }) {
    let history = useHistory();
    let _lang = new URLSearchParams(window.location.search).get("lang");
    const {setReservation, reservation } = useContext(ReservationContext);

    if (_lang !== null) {
        lang = _lang;
    }

    if (lang === "eng") {
        moment.locale("en");
    } else {
        moment.locale("es");
    }

    const [tripTypes, setTripTypes] = useState([
        {
            value: "One Way",
            label: "One Way",
        },
        {
            value: "Round Trip",
            label: "Round Trip",
        },
    ]);
    const [tripTypes2, setTripTypes2] = useState([
        {
            value: "One Way",
            label: "One Way",
        },
    ]);
    const [units, setUnits] = useState([]);
    const [airlines, setAirlines] = useState([]);
    const [airlinesOptions, setAirlinesOptions] = useState([]);
    const [unitsOptions, setUnitOptions] = useState([]);
    const [unitSelected, setUnitSelected] = useState({ image: "" });
    const [numPassengers, setNumPassengers] = useState([]);
    const [locations, setLocations] = useState([]);
    const [pickupDate, setPickupDate] = useState('');
    const [departureDate, setDepartureDate] = useState('');
    const [showContactForm, setShowContactForm] = useState(false);
    const [modalConfirmation, setModalConfirmation] = useState(false);
    const [loaderController, setLoaderController] = useState(false);
    const [totalPayment, setTotalPayment] = useState(0);

    // aux values
    const [auxTripType, setAuxTripType] = useState(tripTypes[0]);
    const [auxUnit, setAuxUnit] = useState(null);
    const [auxPickup, setAuxPickup] = useState(null);
    const [auxDestination, setAuxDestination] = useState(null);
    const [auxArrivalAirline, setAuxArrivalAirline] = useState(null);
    const [auxDepartureAirline, setAuxDepartureAirline] = useState(null);

    const [firstFormValidator, setFirstFormValidator] = useState(false);
    const [secondStepValidator, setSecondStepValidator] = useState(false);
    const [roundTripValidation, setRoundTripValidation] = useState(false);
    const [roundTripSelected, setRoundTripSelected] = useState(false);
    const [tripValidator, setTripValidator] = useState(false);
    const [paymentValidator, setPaymentValidator] = useState(false);
    const [validRate, setValidRate] = useState(true);
    const [showCardItem, setShowCardItem] = useState(false);
    const [idBooking, setIdBooking] = useState('')
    const [form, setForm] = useState(reservation);

    const { promotion, promotion_type } = reservation;

     useEffect(() => {
        //console.log(form);
        if (tripValidator === true) {
            setForm({
                ...form,
                trip_type: 'One Way',
            })
            setAuxTripType(tripTypes2[0]);

        }
    }, [tripValidator]);

    const getCodeAux = () => {
        setIdBooking(`TR${moment().year("YY").format("YY")}${moment().month("M").format("MM")}${moment().day("D").format("DD")}-${Math.floor(Math.random() * 99999)}`)
    };
    
    useEffect(() => {
        getCodeAux();
    }, []);

    // const handleContactForm = () => {
    //     setFirstFormValidator(false);
    //     if (form.pickup_location === "" || form.destination_location === "" || form.departure_datetime === null || form.pickup_datetime === null || totalPayment === 0) {
    //         setFirstFormValidator(true);
    //     } else {
    //         setShowContactForm(!showContactForm);
    //         setSecondStepValidator(!secondStepValidator);
    //     }
    //     setRoundTripValidation(false);
    //     if (form.pickup_location === "Airport SJD" && form.trip_type === "Round Trip") {
    //         setRoundTripValidation(true);
    //     }
    // };

    // const [paymentForm, setPaymentForm] = useState({
    //     card_name: "",
    //     card_number: "",
    //     card_exp_month: "",
    //     card_exp_year: "",
    //     card_cvv: "",
    // });

    useEffect(() => {
        //console.log('Form: ', form);
    }, [form]);

    useEffect(() => {
        getAllUnits();
        getAllLocations();
        getAllAirlines();
    }, []);

    useEffect(() => {
        let aux_passenger_capacity = [];
        for (let index = 0; index < form.passenger_number; index++) {
            aux_passenger_capacity.push(index + 1);
        }
        setNumPassengers(aux_passenger_capacity);
    }, [form.passenger_number]);

    const getAllAirlines = () => {
        getAirlines().then((response) => {
            if (response.data.length > 0) {
                let _airlines = response.data;
                setAirlines(_airlines);
                let auxAirlinesOptions = [];
                _airlines.forEach((item) => {
                    auxAirlinesOptions.push({
                        label: item.label,
                        value: item.name,
                    });
                });
                setAirlinesOptions(auxAirlinesOptions);
            }
        });
    };

    const getAllUnits = () => {
        getUnits().then((response) => {
            if (response.data.length > 0) {
                let _units = response.data;
                setUnits(_units);
                setUnitSelected(_units[0]);

                let auxUnitOptions = [];
                _units.forEach((item) => {
                    auxUnitOptions.push({
                        label: item.label,
                        value: item.label,
                        id_unit: item.id_unit,
                    });
                });

                setUnitOptions(auxUnitOptions);
                setForm({
                    ...form,
                    unit: _units[0].label,
                    passenger_number: _units[0].capacity,
                    unit_id: _units[0].id_unit,
                });
                setAuxUnit(auxUnitOptions[0]);
            }
        });
    };

    const getAllLocations = () => {
        getLocations().then((response) => {
            setLocations(response.data);
        });
    };

    useEffect(() => {
        if (form.pickup_id !== "" && form.destination_id !== "" && form.unit_id !== "") {
            if (form.destination_id === 1) {
                getRateByPlaces(form.destination_id, form.pickup_id, form.unit_id).then((response) => {
                    if (response.data.length === 0) {
                        setTotalPayment(0);
                        setValidRate(false);
                    } else {
                        setValidRate(true);
                        if (form.trip_type === "One Way") {
                            setTotalPayment(response.data[0].oneWay);
                        } else {
                            setTotalPayment(response.data[0].roundTrip);
                        }
                    }
                });
            } else {
                getRateByPlaces(form.pickup_id, form.destination_id, form.unit_id).then((response) => {
                    if (response.data.length === 0) {
                        setTotalPayment(0);
                        setValidRate(false);
                    } else {
                        setValidRate(true);
                        if (form.trip_type === "One Way") {
                            setTotalPayment(response.data[0].oneWay);
                        } else {
                            setTotalPayment(response.data[0].roundTrip);
                        }
                    }
                });
            }
        }
        if (form.pickup_location === "Airport SJD" && form.trip_type === "Round Trip") {
            setRoundTripValidation(true);
        } else {
            setRoundTripValidation(false);
        }
        if (form.trip_type === "Round Trip") {
            setRoundTripSelected(true);
        } else setRoundTripSelected(false);
    }, [form.unit, form.pickup_location, form.destination_location, form.trip_type]);

    const handleChangeTripType = (selected) => {
        if (selected.value !== "One Way") {
            setAuxTripType(tripTypes[1]);
            selectMarineCSL();
        } else {
            setForm({
                ...form,
                trip_type: selected.value,
            });
            setAuxTripType(tripTypes[0]);
        }
    };

    const handleChangeVehicle = (selected) => {
        let unit_selected = units.filter((item) => {
            return item.label === selected.value;
        });

        setUnitSelected(unit_selected[0]);
        setForm({
            ...form,
            unit: unit_selected[0].label,
            unit_id: unit_selected[0].id_unit,
            passenger_number: unit_selected[0].capacity,
        });
        setAuxUnit(selected);
    };

    const handleChangeArrivalAirline = (selected) => {
        let airline_selected = airlines.filter((item) => {
            return item.name == selected.value;
        });
        setForm({
            ...form,
            arrival_airline: airline_selected[0].name,
        });
        setAuxArrivalAirline(selected);
    };

    const handleChangeDepartureAirline = (selected) => {
        let airline_selected = airlines.filter((item) => {
            return item.name == selected.value;
        });

        setForm({
            ...form,
            departure_airline: airline_selected[0].name,
        });
        setAuxDepartureAirline(selected);
    };

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangePickup = (pickup_selected) => {
        setForm({
            ...form,
            pickup_location: pickup_selected.value,
            pickup_id: pickup_selected.id_zone,
        });
        setAuxPickup(pickup_selected);
    };

    const handleChangeDestination = (destination_selected) => {
        destination_selected.id_zone === 1 ? setTripValidator(true) : setTripValidator(false);
        setForm({
            ...form,
            destination_location: destination_selected.value,
            destination_id: destination_selected.id_zone,
        });
        setAuxDestination(destination_selected);
        console.log(destination_selected);
    };
    const selectMarineCSL = () => {
        setForm({
            ...form,
            trip_type: "Round Trip",
            destination_location: "Airport SJD",
            destination_id: 1,
        });
        setAuxDestination("Airport SJD");
    };

    const toggleModalConfirmation = () => {
        //console.log('my form', form)
        if (firstFormValidator === true || form.fullname === "" || form.email === "" || form.cellphone === "") {
            Swal.fire("", "Please complete the form to register the reservation.", "warning");
        } else {
            localStorage.setItem(
                "desc",
                `RCI Transroute Reservation ${idBooking} to: ${form.fullname}, Email: ${form.email}, Trip Type: ${form.trip_type}, Pickup Location: ${form.pickup_location}, Destination: ${form.destination_location}`
            );           
            setModalConfirmation(!modalConfirmation);
        }
    };

    // const toggleCloseModal = () => {
    //     setModalConfirmation(!modalConfirmation);
    // };

    const [activeTab, setActiveTab] = useState("1");

    // const toggle = (tab) => {
    //     if (activeTab !== tab) setActiveTab(tab);
    // };

    //save reservacion to db
    const handlePayNow = () => {
        setLoaderController(true);
        let dataToSend = {
            folio: idBooking,
            payment_id: 'Pendiente',
            trip_type: form.trip_type,
            unit: form.unit,
            pickup_location: form.pickup_location,
            destination_location: form.destination_location,
            total_passengers: form.total_passengers,
            fullname: form.fullname,
            email: form.email,
            cellphone: form.cellphone,
            arrival_datetime: moment(form.pickup_datetime).format("YYYY-MM-DD"),
            arrival_airline: form.arrival_airline,
            arrival_flight_number: form.arrival_flight_number,
            departure_datetime: moment(form.departure_datetime).format("YYYY-MM-DD"),
            departure_airline: form.departure_airline,
            departure_flight_number: form.departure_flight_number,
            observations: form.observations,
            payment_method: "card",
            discount_code: "0",
            discount_percent: 10,
            total_payment: form.rate_promotion,
        };
        postReservation(dataToSend) // save reservation
            .then((res) => {
                console.log(res);
                if(res.status === 200){
                    history.push({ pathname: `/checkout/${idBooking}`, state: {form, lang} });
                    window.location.reload();
                }
            }).catch((e) => {
                console.log("Error:1:", e);
                setLoaderController(false);
                Swal.fire("Oops!", "Something went wrong when creating reservation... try again later.", "error");
            });
    };
    const formTravelInformation = () => {
        return (
            <div>
                <h3 className="subtitle">{lang === "eng" ? "Select your Transportation Service:" : "Selecciona tu servicio de transportación:"}</h3>
                <FormGroup>
                    <Label>{lang === "eng" ? "Select service" : "Selecciona servicio"}</Label>
                    <InputGroup className="tr-select">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon icon="route" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Select
                            value={auxTripType}
                            classNamePrefix="tr"
                            className="select-transroute"
                            options={tripValidator ? tripTypes2 : tripTypes}
                            onChange={handleChangeTripType}
                        />
                    </InputGroup>                    
                </FormGroup>
                <Row className="px-0">
                    <FormGroup className="col-12 col-lg-6">
                        <Label>{lang === "eng" ? "Select vehicle transport" : "Seleccione vehículo de transporte"}</Label>
                        <InputGroup className="tr-select">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="shuttle-van" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Select value={auxUnit} classNamePrefix="tr" className="select-transroute" options={unitsOptions} onChange={handleChangeVehicle} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-6">
                        <Label>{lang === 'eng' ? 'Passengers number:' : 'Número de pasajeros:'}</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="user-friends" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="select" id="total_passengers" onChange={handleChange} name="total_passengers">
                                {
                                    numPassengers.map((item, pos) => (
                                        <option key={pos}>{item}</option>
                                    ))
                                }
                            </Input>
                        </InputGroup>
                    </FormGroup>
                </Row>
                <FormGroup>
                    <Label>{lang === "eng" ? "Pickup location:" : "Ubicación de recogida:"}</Label>
                    <InputGroup className="tr-select">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon icon="map-marker-alt" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Select value={auxPickup}
                            classNamePrefix="tr"
                            className="select-transroute"
                            options={locations}
                            onChange={handleChangePickup}
                        />
                    </InputGroup>
                </FormGroup>
                    <FormGroup>
                        <Label>{lang === "eng" ? "Destination location:" : "Ubicación de destino:"}</Label>
                        <InputGroup className="tr-select">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="map-marker-alt" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Select value={auxDestination} 
                                lassNamePrefix="tr"
                                className="select-transroute"
                                options={locations}
                                onChange={handleChangeDestination}
                            />
                        </InputGroup>
                    </FormGroup>
                <DateTimePickerForm lang={lang} form={form} setForm={setForm} />
            </div>
        );
    };

    const formContactInformation = () => {
        return (
            <Fragment>
                <h3 className="subtitle">{lang === "eng" ? "Contact / Traveler Information" : "Información de Contacto / Viajero :"}</h3>
                <FormGroup>
                    <Label>{lang === "eng" ? "Enter the traveler ‘s full name" : "Ingrese el nombre completo del viajero"}</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon icon="user" />
                            </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" onChange={handleChange} name="fullname" value={form.fullname} />
                    </InputGroup>
                </FormGroup>
                <Row className="px-0">
                    <FormGroup className="col-12 col-lg-6">
                        <Label>{lang === "eng" ? "Enter main cell number:" : "Ingrese número de contacto:"}</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="phone" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text" maxLength="10" required onChange={handleChange} name="cellphone" value={form.cellphone} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-6">
                        <Label>{lang === "eng" ? "Enter the contact's e-mail" : "Ingresa el e-mail del contacto"}</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="envelope" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input type="text" onChange={handleChange} name="email" value={form.email} />
                        </InputGroup>
                    </FormGroup>
                </Row>
                <h3 className="my-3">{lang === "eng" ? "Arrival Information:" : "Información de llegada:"}</h3>
                <Row className="px-0">
                    <FormGroup className="col-12 col-lg-6">
                        <Label>{lang === "eng" ? "Airline's name:" : "Nombre de la aerolínea:"}</Label>
                        <InputGroup className="tr-input">
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="plane-arrival" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Select
                                value={auxArrivalAirline}
                                classNamePrefix="tr"
                                className="select-transroute"
                                options={airlinesOptions}
                                onChange={handleChangeArrivalAirline}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className="col-12 col-lg-6">
                        <Label>{lang === "eng" ? "Flight's number:" : "Número de vuelo:"}</Label>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon="plane-arrival" />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                onChange={handleChange}
                                name="arrival_flight_number"
                                value={form.arrival_flight_number}
                            />
                        </InputGroup>
                    </FormGroup>
                </Row>
                <div className="departure-information">
                    {form.trip_type == "Round Trip" && (
                        <Fragment>
                            <h3 className="my-3">{lang === "eng" ? "Departure Information:" : "Información de partida:"}</h3>
                            <Row className="px-0">
                                <FormGroup className="col-12 col-lg-6">
                                    <Label>{lang === "eng" ? "Airline's name:" : "Nombre de la aerolínea:"}</Label>
                                    <InputGroup className="tr-input">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <FontAwesomeIcon icon="plane-departure" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Select
                                            value={auxDepartureAirline}
                                            classNamePrefix="tr"
                                            className="select-transroute"
                                            options={airlinesOptions}
                                            onChange={handleChangeDepartureAirline}
                                            placeholder="Type or select"
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className="col-12 col-lg-6">
                                    <Label>{lang === "eng" ? "Flight's number:" : "Número de vuelo:"}</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <FontAwesomeIcon icon="plane-departure" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            onChange={handleChange}
                                            name="departure_flight_number"
                                            value={form.departure_flight_number}
                                        />
                                    </InputGroup>
                                </FormGroup>
                            </Row>
                        </Fragment>
                    )}
                </div>

                <Row className="px-0 my-3">
                    <Col xs={12}>
                        <FormGroup>
                            <h3>{lang === "eng" ? "Observations:" : "Observaciones"}</h3>
                            <small>{lang === "eng" ? "Do you have any observations of special request?" : "¿Tienes alguna observación o petición especial?"}</small>
                            <InputGroup>
                                <Input className="textarea-observations" type="textarea" name="observations" value={form.observations} onChange={handleChange} />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <div className="mt-12">
                            <Button className="my-2 w-50" onClick={toggleModalConfirmation} color="primary">
                                <FontAwesomeIcon className="mr-2" icon="calendar-check" />
                                {lang === "eng" ? "CONFIRM RESERVATION" : "CONFIRMAR RESERVACIÓN"}
                            </Button>
                        </div>
                    </Col>
                    {/** Antes aqui estaba el metodo de pago */}
                </Row>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <LoadingOverlay active={loaderController} spinner text="Processing data...">
                <Container>
                    <Row>
                         <Col xs={12}>
                            {/* <img className="img-fluid" src="https://picsum.photos/id/12/1200/200" /> */}
                            <h1>{lang === "eng" ? "Book online your Ground Transportation" : "Reserva tu transportación"}</h1>
                        </Col>
                        <Col xs={12} md={6} lg={8}>
                            <Form className="booking-form">
                                <div className="booking-step1">
                                    {!showContactForm && formTravelInformation()}

                                    <Row className="px-0 my-3">
                                        {firstFormValidator && (
                                            <Col xs={12}>
                                                <Alert color="danger" className="text-center">
                                                    {lang === "eng" ? "You need to complete this section to continue." : "Necesitas completar esta sección para continuar."}
                                                </Alert>
                                            </Col>
                                        )}
                                        {!validRate && (
                                            <Col xs={12}>
                                                <Alert color="danger" className="text-center">
                                                    {lang === "eng" ? "You need to choose a pickup and destination valid to continue." : "Necesitas seleccionar una tarifa correcta para continuar."}
                                                </Alert>
                                            </Col>
                                        )}
                                        {/* <Col xs={12}>
                                            {showContactForm ? (
                                                <p className="link-modify cursor-pointer text-right" onClick={handleContactForm}>
                                                    <FontAwesomeIcon icon="edit" /> {lang === "eng" ? "MODIFY" : "MODIFICAR"}
                                                </p>
                                            ) : (
                                                <Button block color="primary" className="w-50" onClick={handleContactForm}>
                                                    {lang === "eng" ? "CONTINUE" : "CONTINUAR"}
                                                </Button>
                                            )}
                                        </Col> */}
                                    </Row>
                                </div>
                               {formContactInformation()}
                            </Form>
                        </Col>
                        {/* ////  DETAILS SIDEBAR */}
                        <Col xs={12} md={6} lg={4}>
                            <Sidebar setForm={setForm} data={form} unit={unitSelected} lang={lang} totalPayment={totalPayment} tripValidator={tripValidator} />
                        </Col>
                    </Row>
                    <Modal isOpen={modalConfirmation} toggle={toggleModalConfirmation} className="modal-lg">
                        <ModalBody>
                            <Summary
                                lang={lang}
                                title={lang === "eng" ? "Please Confirm your reservation" : "Por favor confirma tu reservación"}
                                data={form}
                                unit={unitSelected}
                                totalPayment={totalPayment}
                            />

                                <Col xs={12} className="payment">
                                {/* <h3 className="my-3">
                                    <FontAwesomeIcon icon="credit-card" className="mr-3" /> {lang === "eng" ? "Payment details" : "Método de Pago"}
                                </h3> */}
                                {/*  <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                            <FontAwesomeIcon icon="credit-card" /><br />
                                            {lang === 'eng' ? 'CREDIT CARD' : 'TARJETA DE CRÉDITO'}
                                        </NavLink>
                                    </NavItem>
                                </Nav> */}
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col xs="12">
                                                <div className="pymnt-itm active">
                                                    <div className="pymnt-cntnt">
                                                        <Button style={{width:'100%'}} color="primary" onClick={() => handlePayNow()}>
                                                            <span style={{color:'#ffffff', textDecoration:'none'}}>PAY NOW</span></Button>
                                                        {/*<PaymentPaypal/>
                                                        {/* <iframe src={`${configs.URL_PAYMENT_PAYPAL}/?paypal=true&description=${localStorage.getItem('desc')}&idReservation=${localStorage.getItem('idReservation')}&pickupId=${form.pickup_id}&destinationId=${form.destination_id}&transportId=${form.unit_id}&typeTrip=${form.trip_type}&rate=${totalPayment}`} frameBorder="0" style={{height:'350px', overflowY:'auto', width:'100%'}}/> */}
                                                        {/* <PaymentStripe toggleCloseModal={toggleCloseModal} totalPayment={totalPayment} lang={lang} handlePayNow={handlePayNow} /> */}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    {/* <TabPane tabId="2">
                                        <Row>
                                            <Col xs="12">
                                                <h4>Cash Payment</h4>
                                                <p>This method of payment requires payment in cash when picked up at the airport or Hotel</p>
                                            </Col>
                                        </Row>
                                    </TabPane> */}
                                </TabContent>
                                {/*  <Benefits /> */}
                            </Col>
                        </ModalBody>
                        {/*<ModalFooter>
                           <Button onClick={toggleModalConfirmation} color="secondary">{lang === 'eng' ? 'Cancel' : 'Cancelar'}</Button>
                            <Button color="primary">{lang === 'eng' ? 'Confirm and Send Email Voucher' : 'Confirmar y envíar Voucher por E-mail'}</Button>
                        </ModalFooter>
                        */}
                    </Modal>
                </Container>
            </LoadingOverlay>
        </Fragment>
    );
}
