import React, { Fragment, useContext, useEffect  } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import 'moment/locale/cs.js';
import 'moment/locale/es.js';
import 'moment/locale/fr.js';
import 'moment/locale/nl.js';
import moment from 'moment';
import { ReservationContext } from '../../context/reservation.context';
import { parseToPromotionRate } from '../utils/utils';

export default function Sidebar({ data, setForm, unit, lang, totalPayment, tripValidator }) {

    const { reservation } = useContext(ReservationContext);
    const { promotion, promotion_type } = reservation;

    if(lang === 'eng'){
        moment.locale('en');
    }else{
        moment.locale('es');
    }

    const { 
        trip_type,
        total_passengers,
        destination_location,
        pickup_date,
        pickup_time,
        pickup_location,
        arrival_airline,
        arrival_flight_number,
        departure_flight_time,
        departure_date,
        departure_airline,
        departure_flight_number,
        fullname,
        cellphone,
        email
    } = data;

    const pickup_datetime = pickup_date +' - '+ pickup_time

    const { image, label } = unit

    if(lang === 'eng'){
        moment.locale('en');
    } else{
        moment.locale('es');
    }
    useEffect(() => {
      if(reservation.promotion) {
        let price_promotion = parseToPromotionRate(totalPayment)
        console.log(price_promotion)
        setForm({
            ...data,
            rate_promotion: price_promotion,
            rate_regular: totalPayment
        })
      }
    }, [reservation, totalPayment])
    

    return (
        <Fragment>
            <div className="summary-content">
                <h3>{lang === 'eng' ? 'Your Reservation Summary:' : 'Tu reservación:'}</h3>
                <Alert color="warning">
                    <h4>Promotion: <span>{`${promotion} ${promotion_type === 'percent' ? "%" : "usd"}`}</span> off</h4>
                    {
                        totalPayment ? <>
                            <hr style={{ borderColor:'lightgray' }} />
                            <h5 style={{ textDecoration:'line-through' }}>Regular Price: <span>${data.rate_regular} USD</span></h5>
                            <h5 style={{ fontSize:'32px' }}>TOTAL: <span>${data.rate_promotion} USD</span></h5>
                        </>
                        : null
                    }
                </Alert>
                { 
                    image  && image !== 'No image' ? <>
                        <h4>{lang === 'eng' ? 'Transport Selected:' : 'Transporte Seleccionado'}</h4>
                        <img className="img-fluid" src={image} alt={'unidad Transroute'} />
                    </>
                    :
                    null
                }
                <p>{lang === 'eng' ? 'Service:' : 'Servicio:'}<br /> <span>{trip_type}</span></p>

                <Row className="px-0">
                    <Col xs={6}>
                        <p>{lang === 'eng' ? 'Transport:' : 'Transporte:'}<br /> <span>{label}</span></p>
                    </Col>
                    <Col xs={6}>
                        <p>{lang === 'eng' ? 'Passenger:' : 'Pasajeros:'}<br /> <span>{total_passengers}</span></p>
                    </Col>
                </Row>
                {
                    pickup_location &&
                        <p>{lang === 'eng' ? 'Pickup Location:' : 'Ubicación de recogida:'}<br /> <span>{pickup_location}</span></p>
                }
                {
                    destination_location &&
                        <p>{lang === 'eng' ? 'Destination Location:' : 'Ubicación de destino:'}<br /> <span>{destination_location}</span></p>
                }
                {
                    pickup_datetime &&
                        <h4>{lang === 'eng' ? tripValidator ? 'Departure Information' : 'Origin Pickup Information:' : tripValidator ? 'Información de partida' : 'Información de llegada'}</h4>
                }
                {
                    pickup_datetime &&
                        <p>{lang === 'eng' ? 'Pickup Date/Time:' : 'Fecha/Hora de recogida'} <br /> <span>{pickup_datetime}</span></p>
                }

                <Row className="px-0">
                    <Col xs={6}>
                        <p>{lang === 'eng' ? "Airline's name:" : 'Nombre de la aerolínea:'}<br /> <span>{arrival_airline}</span></p>
                    </Col>
                    <Col xs={6}>
                        <p>{lang === 'eng' ? "Flight's number:" : 'Número de vuelo:'}<br /> <span>{arrival_flight_number}</span></p>
                    </Col>
                </Row>

                {trip_type === 'Round Trip' &&
                    <Fragment>
                        {
                            departure_date && <>
                                <h4>{lang === 'eng' ? 'Departure Information:' : 'Información de partida:'}</h4>
                                <p>{lang === 'eng' ? 'Pickup Date:' : 'Fecha Salida:'}<br /> <span>{departure_date} {departure_flight_time}</span></p>
                            </>

                        }
                        <Row className="px-0">
                            <Col cs={6}>
                                <p>{lang === 'eng' ? "Airline's name:" : 'Nombre de la aerolínea:'}<br /> <span>{departure_airline}</span></p>
                            </Col>
                            <Col xs={6}>
                                <p>{lang === 'eng' ? "Flight's number:" : 'Número de vuelo:'}<br /> <span>{departure_flight_number}</span></p>
                            </Col>
                        </Row>
                        
                    </Fragment>
                }
                <Fragment>
                    <h4>{lang === 'eng' ? 'Contact / Traveler Information' : 'Información de Contacto / Viajero :'}</h4>
                    <p>{lang === 'eng' ? 'Full name:' : 'Nombre completo:'} <br /> <span>{fullname}</span></p>
                    <p>{lang === 'eng' ? 'Phone / Cell number:' : 'Teléfono:'} <br /> <span>{cellphone}</span></p>
                    <p>Email:<br /> <span>{email}</span></p>
                </Fragment>
            </div>
        </Fragment>
    )
}
