import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams, useHistory} from 'react-router-dom';
import { Container , Row, Col, Button } from 'reactstrap';
import { getReservationByFolio } from '../../api_controllers/reservations_controller';
import configs from "../../config/config";
import Summary from './summary'

export default function Confirmation() {
    // let history = useHistory();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [paymentID, setPaymentID] = useState('');

    const getReservation = (id) => {
        getReservationByFolio(id).then((response) => {
            if (response.data.length > 0) {
                let reserve = response.data;
                setData(reserve[0]);
                setPaymentID(reserve[0].payment_id);
            }
        });
    };
    // const validataIfPayment = (payment_id, id) => {
    //     console.log(payment_id);
    //     if(payment_id === 'Pendiente'){
    //         history.push({ pathname: `/checkout/${id}`});
    //         window.location.reload();
    //     }
    // }
    useEffect(() => {
        getReservation(id)
    }, [id]);

    // useEffect(() => {
    //     validataIfPayment(paymentID, id)
    // }, [paymentID, id]);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Confirmation Page - RCI Transroute Booking Transpotation</title>
                <link rel="canonical" href={`${configs.ENVIRONMENT === 'DEVELOPMENT' ? configs.BASE_URL_DEV : configs.BASE_URL_PROD}/confirmation`} />
            </Helmet>
            <Container className='confirmation-page'>
                <Row className="my-3">
                    <Col xs={12} md={6}>
                        <h1>Book Confirmation</h1>
                        {
                            paymentID === 'Pendiente' && <>
                                <h2 style={{color: 'red'}}>Your Payment is Pendent.<br /> Please do your payment</h2>
                                <a style={{textDecoration:'underline', fontWeight:'bold'}} href={`/checkout/${id}`}>Pay Now Online with Visa and Master Card</a>
                                <br />
                                <br />
                                <a style={{textDecoration:'underline', fontWeight:'bold'}}
                                    href={`https://wa.me/6241259657?text=I+Need+to+Pay+with+Amex+Card+Please,+Transportation+for+La+Isla+Reservation+ID:+${id}&type=phone_number&app_absent=0`}
                                >Click for Request Pay with American Express</a>
                            </>

                        }
                    </Col>
                    <Col xs={12} md={6} style={{display:'flex',alignItems: 'center', justifyContent:'flex-end'}}>
                        <div>
                            <h3 style={{textAlign: 'right', fontWeight:'700'}}>Total: ${data?.total_payment} USD</h3>
                            <h4 style={{textAlign: 'right', color: '#000000', fontWeight:'700'}}>Reservation ID: {data?.folio}<br /></h4>
                            <h5 style={{textAlign: 'right', color: '#000000', fontWeight:'700'}}>Paypment ID: {data?.payment_id}<br /></h5>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Summary data={data} title="Book Confirmation" page="confirmation" />
                    </Col>
                </Row>               
            </Container>
        </>
    )
}
