export const reservationData = {
    arrival_airline: '',
    arrival_flight_number: '',
    cellphone: '',
    departure_airline: '',
    departure_date: '',
    departure_time: '',
    departure_flight_number: '',
    departure_flight_time: '',
    departure_pickup_time_hotel:'',
    destination_id: '',        
    destination_location: '',
    email: '',
    fullname: '',
    observations: '',
    passenger_number: 0,
    pickup_date: '',
    pickup_time: '',
    pickup_id: '',
    pickup_location: '',
    promotion:10,
    promotion_type:'percent',
    rate_regular:0,
    rate_promotion:0,
    trip_type: 'One Way',
    total_passengers: 1,
    unit_id: '',
    unit: ''
    }