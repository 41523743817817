import { useContext, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap'
import { ReservationContext } from '../../context/reservation.context';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import moment from 'moment';
registerLocale("es", es);
registerLocale("en", en);

export const DateTimePickerForm = ({form, setForm}) => {

    const { lang } = useContext(ReservationContext);
    const { trip_type } = form;
    
    const [pickupDate, setPickupDate] = useState(form.pickupDate)
    const [pickupTime, setPickupTime] = useState(form.pickupTime)
    const [minArrivalDateToSelect, setMinArrivalDateToSelect] = useState('')

    const [departureDate, setDepartureDate] = useState(form.departure_date)
    const [departureFlightTime, setDepartureFlightTime] = useState(form.departure_flight_time)
    const [minDepartureDateToSelect, setminDepartureDateToSelect] = useState('')
    const [departureHotelTime, setDepartureHotelTime] = useState(form.departure_pickup_time_hotel)
    
    ////==========================/ arrival methods 
    const handlePickupDate = (date) => {
        setPickupDate( moment(date).format("MMMM DD YYYY") )
        setDepartureDate('')
        setForm({
            ...form,
            pickup_date: moment(date).format("MMMM DD YYYY"),
            departure_date: ''
        })
    }
    const handlePickupTime = (time) => {
        setPickupTime(time);
        // console.log(time)
        setForm({
            ...form,
            pickup_time: moment(time).format("h:mm A")// concatenamos para enviarlo asi a la db y no modificar campos en la db
        })
    }
    const getMinimalArrivalDateToSelect = () => {
        let minDate = new Date()
        let minimunArrivalDay = minDate.setDate(minDate.getDate() + 1)
        setMinArrivalDateToSelect(minimunArrivalDay);
    }
    useEffect(() => {
        getMinimalArrivalDateToSelect()
    }, [])
    ////==========================/ departure methods
    const handleDepatureDate = (date) => {
        setDepartureDate(date)
        setForm({
            ...form,
            departure_date: moment(date).format("MMMM DD YYYY")
        })
    }
    const handleDepartureFlightTime = (time) => {
        setDepartureFlightTime(time);
        let timeHotelPickup = time.getTime();
        let hour = 180 * 60000; // 180 minutes = 3 hours
        timeHotelPickup = timeHotelPickup - hour;
        setForm({
            ...form,
            departure_flight_time: moment(time).format("h:mm A"),// concatenamos para enviarlo asi a la db y no modificar campos en la db
            departure_pickup_time_hotel: moment(timeHotelPickup).format("h:mm A")
        })
        getDeparturePickupHotelTimeLimited(time)

    }
    const getMinimalDepartureDateToSelect = (date) => {
        let minDate = date.setDate(date.getDate() + 1);
        setminDepartureDateToSelect(minDate)
    }
    const handleDepartureHotelTime = (time) => {        
        setDepartureHotelTime(time);
        setForm({
            ...form,
            departure_pickup_hotel_time: moment(time).format("h:mm A")
        });
    }
    
    const getDeparturePickupHotelTimeLimited = (time) => {
        // set time limited for select in input 2 hours before of flight time
        let hour = 120 * 60000;
        let flightTime = departureFlightTime - hour;
        console.log(flightTime)
        let selectedTime = new Date(time)
        return selectedTime.getTime();
    }
    useEffect(() => {
        if(trip_type === 'Round Trip') {
            if(pickupDate) {
                let date = new Date(pickupDate)
                getMinimalDepartureDateToSelect(date)
            }
        }
    }, [trip_type, pickupDate])
    ////////////////////////////////////////////////////////////////
  return (
    <Row className="px-0">
        <FormGroup className="pickupDate col-12 col-lg-6">
            <Label>{lang === 'eng' ? 'Pickup Date:' : 'Fecha de recogida'}</Label>
            <InputGroup className="tr-input">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                    </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                    locale={lang === 'eng' ? 'en' : 'es'}
                    selected={''}
                    value={pickupDate}
                    onChange={handlePickupDate}
                    className="tr-datepicker"
                    dateFormat="MM/dd/yyyy"
                    minDate={minArrivalDateToSelect}
                />
            </InputGroup>
        </FormGroup>
        <FormGroup className="pickupTime col-12 col-lg-6">
            <Label>{lang === 'eng' ? 'Pickup Time Picker:' : 'Hora de recogida'}</Label>
            <InputGroup className="tr-input">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        <i className="fa fa-clock-o" aria-hidden="true"></i>
                    </InputGroupText>
                </InputGroupAddon>
                <DatePicker
                    locale={lang === 'eng' ? 'en' : 'es'}
                    selected={pickupTime}
                    value={pickupTime}
                    onChange={handlePickupTime}
                    className="tr-datepicker"
                    showTimeSelect
                    showTimeSelectOnly                    
                    timeIntervals={5}
                    dateFormat="h:mm aa"
                />
            </InputGroup>
        </FormGroup>
        {
            trip_type === 'Round Trip' && <>
                <FormGroup className="departureDate col-12 col-lg-6">
                    <Label>{lang === 'eng' ? 'Departure Date:' : 'Fecha partida'}
                    {
                        !pickupDate && <><br/><small style={{color:'tomato'}}>Select Pickup Date</small></>
                    }
                    </Label>
                    <InputGroup className="tr-input">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fa fa-calendar" aria-hidden="true"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <DatePicker
                            locale={lang === 'eng' ? 'en' : 'es'}
                            selected={departureDate}
                            value={departureDate}
                            onChange={handleDepatureDate}
                            className="tr-datepicker"
                            dateFormat="MM/dd/yyyy"
                            minDate={minDepartureDateToSelect}
                            disabled={pickupDate ? false: true}
                        />
                    </InputGroup>
                </FormGroup>
                <FormGroup className="departureFlightTime col-12 col-lg-6">
                    <Label>{lang === 'eng' ? 'Departure Flight Time:' : 'Hora de partida del vuelo'}
                    {
                        !pickupDate && <><br/><small style={{color:'tomato'}}>Select Pickup Date</small></>
                    }
                    </Label>
                    <InputGroup className="tr-input">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fa fa-clock-o" aria-hidden="true"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                        <DatePicker
                            locale={lang === 'eng' ? 'en' : 'es'}
                            value={departureFlightTime}
                            selected={departureFlightTime}
                            onChange={handleDepartureFlightTime}
                            className="tr-datepicker"
                            showTimeSelect
                            showTimeSelectOnly                  
                            timeIntervals={5}
                            dateFormat="h:mm aa"
                            disabled={pickupDate ? false: true}
                        />
                    </InputGroup>
                </FormGroup>               
                {
                    form?.departure_pickup_time_hotel && ( 
                        <Col style={{border:'1px solid #b00505',borderRadius:'8px',padding:'1rem', color:'#b00505', fontWeight:'600'}} className="col-12">
                            <p className="text-center p-0 m-0" >
                                {lang === 'eng' ? `NOTE: Hotel pickup time is 3 hours before the flight departure: ${form.departure_pickup_time_hotel}` : `Hora de recogida en hotel son 3 horas antes de la salida del vuelo ${form.departure_pickup_time_hotel}`}
                            </p>
                        </Col>
                    )
                }                        
            </>
        }
   
    </Row>
  )
}
